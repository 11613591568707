import { customersApi } from '@/api';
import { USER_FUNCTIONS_DESC } from '@/config/constants';

const usersFilters = [
  {
    name: 'givenName',
    label: 'givenName',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'familyName',
    label: 'familyName',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'customerName',
    label: 'customerName',
    type: 'autocomplete',
    autocompleteSearch: async (queryString, cb) => {
      const customersResponse = await customersApi.findCustomersByName(queryString);
      const filteredResults = customersResponse.data.map(item => (
        {
          label: item.name,
          value: item.name,
        }
      ));
      cb(filteredResults);
    },
    whereOperator: '=',
  },
  {
    name: 'function',
    label: 'function',
    type: 'select',
    whereOperator: '=',
    options: Object.keys(USER_FUNCTIONS_DESC)
      .map(key => ({ label: `enums.functions.${USER_FUNCTIONS_DESC[key]}`, value: key })),
  },
  {
    name: 'isEnabled',
    label: 'activeInactive',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'filters.active', value: 1 },
      { label: 'filters.inactive', value: 0 },
    ],
  },
];

export default usersFilters;
