export default {
  accreditedTestModal: {
    abort: 'Anular',
    confirm: 'Finaliza la edición y añade el análisis',
    description: 'Para agregar este análisis, primero debe completar el campo «Lote» para ',
    lot: 'Lote por muestra «{0}»:',
    next: 'Edita y pasa a la siguiente muestra',
    samples: 'ninguna muestra | otra {n} muestra | otras {n} muestras',
    title: '¡Atención!',
    warning: 'Atención, análisis acreditados',
  },
  analyses: {
    create: {
      abortCreate: 'Anular',
      accreditedTest: 'Prueba acreditada',
      analysisTypeFilter: 'Filtrar por tipo',
      brand: 'Marca',
      brandCode: 'Código de marca',
      class: 'Clase',
      classType: 'Tipo de clase',
      code: 'Código artículo',
      commodityCategory: 'Descripción del código de mercancía',
      commodityCode: 'Categoría de producto',
      department: 'Departamento',
      departmentCode: 'Código de departamento',
      description: 'Descripción del artículo',
      dynamicClass: 'Clase dinámica',
      expirationDate: 'Fin de la validez del artículo',
      extraDescription: 'Descripción adicional del artículo',
      family: 'Familia',
      familyCode: 'Código de familia',
      fixedClass: 'Clase fija',
      formHeader: 'Crear análisis/clase',
      maxAnalysesNumber: 'Número máximo de análisis',
      measure: 'Descripción de las medidas',
      measureCode: 'Código de medida',
      measureUnitCode: 'Unidad de medida',
      method: 'Descripción del método',
      methodCode: 'Código de método',
      nature: 'Descripción del código natura',
      natureCode: 'Código natura',
      removeAnalysis: 'borrar',
      searchAnalyses: 'Búsqueda de análisis',
      singleAnalysis: 'Análisis',
      statisticCategory: 'Descripción categoría estadística',
      statisticCode: 'Código de categoría estadística',
      submitButton: 'Enviar',
      success: 'El análisis se creó correctamente',
    },
    index: {
      createAnalysis: 'Crear análisis/clase',
    },
    update: {
      addToClass1: 'Añadir a una clase',
      addToClass2: 'Añadir a la clase',
      formHeader: 'Editar análisis',
      success: 'El análisis se actualizó correctamente',
    },
  },
  collapse: {
    CHIM_approved: 'RdP químico',
    CHIM_draft: 'Vista previa de RdP Chim.',
    MICR_approved: 'RdP microbiológico',
    MICR_draft: 'Vista previa de RdP Micro.',
    Preview_draft: 'Vista previa de Excel',
  },
  common: {
    noPermissonRoute: '¡No tienes los permisos para acceder a esta página!',
  },
  customers: {
    create: {
      abortCreate: 'Anular',
      address: 'Dirección',
      agent: 'Agente',
      canViewRawData: 'Ver datos brutos',
      certifiedEmail: 'Correo electrónico',
      city: 'Ciudad',
      createCustomer: 'Crear empresa',
      district: 'Provincia',
      fiscalCode: 'Código Fiscal',
      internalReference: 'Onda Id',
      isEnabled: 'Activado',
      mobile: 'Móvil',
      name: 'Nombre',
      phoneNumber: 'Teléfono',
      sdi: 'SDI',
      success: 'Empresa creada con éxito',
      vat: 'Número de Identificación Fiscal',
      zip: 'Código Postal',
    },
    update: {
      success: 'La empresa se actualizó correctamente',
      updateCustomer: 'Editar empresa',
    },
  },
  dynamicAnalysesModal: {
    abort: 'Anular',
    confirm: 'Finaliza la edición y añade el análisis',
    wrongNumber: '¡Selecciona {n} el análisis para continuar!',
  },
  enums: {
    accreditedTest: {
      no: 'No',
      partially: 'Contiene pruebas acreditadas',
      yes: 'Sí',
    },
    analysisStatuses: {
      accepted: 'Aceptado',
      completed: 'Completado',
      deleted: 'Eliminado',
      inprogress: 'En proceso',
      none: 'Creado',
      pending: 'En Espera',
      rejected: 'Rechazado',
      suspended: 'Suspendido',
    },
    commodityCategory: {
      chemical: 'Químico',
      microbiological: 'Microbiológico',
      vitro: 'Vitro',
    },
    commons: {
      no: 'No',
      yes: 'Sí',
    },
    complifeLaboratory: {
      CHIM: 'Químico',
      MICR: 'Microbiológico',
    },
    enabledStatus: {
      disabled: 'inhabilitado',
      enabled: 'Habilitado',
    },
    functions: {
      administration: 'Administración',
      logistics: 'Logística',
      production: 'Producción',
      purOff: 'Departamento de compras',
      qa: 'Garantía de calidad',
      qc: 'Control de calidad',
      rd: 'Investigación y desarrollo',
      undefined: '-',
    },
    newsStatuses: {
      published: 'Publicado',
      unpublished: 'Borrador',
    },
    offerType: {
      brackets: 'Tramos',
      list: 'Lista de precios',
      null: 'Ningún',
      quantity: 'Suscripción',
    },
    orderLocked: {
      acceptance: 'Procesamiento bloqueado',
      none: 'Sin bloqueo',
      report: 'Informe de prueba bloqueado',
    },
    orderStatuses: {
      accepted: 'Aceptado',
      canceled: 'Eliminado',
      completed: 'Completado',
      draft: 'Borrador',
      draftCompleted: 'Borrador completo',
      inProgress: 'En proceso',
      rejected: 'Rechazado',
      sent: 'Enviado',
    },
    sampleStatuses: {
      accepted: 'Aceptado',
      canceled: 'Eliminado',
      concluded: 'Finalizado',
      created: 'Creado',
      draft: 'Borrador',
      inprogress: 'En proceso',
      pending: 'En Espera',
      rejected: 'Rechazado',
      suspended: 'Suspendido',
    },
  },
  filters: {
    accreditedTest: 'Prueba acreditada',
    actions: 'acciones',
    active: 'Habilitado',
    activeInactive: 'Habilitado/Deshabilitado',
    additionalDescription: 'Descripción adicional',
    address: 'Dirección',
    analysisCode: 'Código de Análisis',
    analysisCount: 'Total Análisis',
    applyFilter: 'Aplicar filtros',
    canViewRawData: 'Datos Brutos',
    childrenCount: 'Número de análisis',
    city: 'Ciudad',
    class: 'Clase',
    clearFilter: 'Vacía',
    code: 'Código artículo',
    comment: 'Comentar',
    commodityCategory: 'Categoría de producto',
    companyName: 'Nombre de la empresa',
    complifeLaboratory: 'Laboratorio',
    countryCode: 'Código de idioma',
    createdAt: 'Fecha',
    customDescription: 'Descripción',
    customer: 'Empresa',
    customerCode: 'Código cliente',
    customerName: 'Razón Social',
    customerOrderNumber: 'Número de pedido del cliente',
    customerProductName: 'Código de muestra cliente',
    date: 'Fecha',
    description: 'Descripción',
    disabled: 'inhabilitado',
    docNumber: 'Número de documento',
    draft: 'Borrador',
    email: 'Correo electrónico',
    emailAddress: 'Dirección de correo electrónico',
    enabled: 'Habilitado',
    endDate: 'A',
    expirationDate: 'Fecha de fin de validez',
    familyName: 'Apellido',
    from: 'Desde',
    fromDate: 'Desde',
    function: 'Función',
    givenName: 'Nombre',
    inactive: 'Deshabilitado',
    invoiceNumber: 'Número de factura',
    isEnabled: 'Activado',
    isVerified: 'Verificado',
    issuedAt: 'Fecha del documento',
    labSampleCode: 'Código de muestra',
    lot: 'Lote',
    method: 'Método',
    methodCode: 'Código de método',
    name: 'Nombre',
    newsTitle: 'Título de la noticia',
    offer: 'Oferta',
    offerEnabled: 'Activar',
    offerNumber: 'Número de oferta',
    ondaOrderId: 'Id Onda',
    order: 'Pedido',
    orderConfirmId: 'Número de confirmación del pedido',
    orderId: 'ID de pedido',
    orderLocked: 'Pedido bloqueado',
    orderName: 'Nombre del pedido',
    orderNumber: 'Número de pedido',
    ordersSent: 'Enviado',
    phone: 'teléfono',
    price: 'Importe',
    productName: 'Nombre del producto',
    publishedAt: 'Fecha de publicación',
    quantity: 'cantidad',
    rejectReason: 'Motivo de la denegación',
    remaining: 'Restantes',
    reportsList: 'Lista de informes',
    revisionDate: 'Fecha de revisión',
    revisionNumber: 'Número de revisión',
    role: 'Rol',
    sampleCount: 'Número de muestras',
    sampleId: 'ID de Muestra',
    sampleReportCodes: 'Códigos de informe de prueba',
    sector: 'Sector',
    sent: 'Enviado',
    sentAt: 'Fecha de envío del pedido',
    startDate: 'Desde',
    status: 'Estado',
    testType: 'Tipo de prueba',
    title: 'Nombre del pedido',
    toDate: 'A',
    type: 'Tipología',
    user: 'Usuario',
    vat: 'Identificación Fiscal',
    zipCode: 'Código Postal',
  },
  global: {
    add: 'Añadir',
    apply: 'Aplicar',
    attachment: 'Anexo',
    back: 'Atràs',
    backToList: 'Volver al listado',
    browse: 'Navegar',
    cancel: 'Anular',
    confirmUploadCancel: '¿Está seguro de que desea eliminar el archivo?',
    connectionError: '¡Error de comunicación con el servidor!',
    continue: 'Continuar',
    downloadPdf: 'Descargar PDF',
    edit: 'Editar',
    english: 'Inglés',
    fileExceeded: 'Carga un archivo de < {0} Mb de tamaño',
    help: 'Ayuda',
    italian: 'Italiano',
    lang: 'Idioma',
    noMultipleFilesUpload: '¡debes eliminar el archivo anterior antes de poder cargar uno nuevo!',
    notCsvFile: '¡Carga un archivo csv válido!',
    notPdfFile: '¡Cargar un archivo pdf válido!',
    removeSwitchPdf: 'Sustituir/Eliminar PDF',
    requiredFields: 'Los campos marcados con * son obligatorios',
    select: 'Seleccionar',
    spanish: 'Español',
    uploadFilesLimitExceeded: 'Se ha alcanzado el número máximo de archivos que se pueden cargar',
    uploadPdf: 'Cargar PDF',
    warning: '¡Atención!',
  },
  header: {
    titles: {
      analysesIndex: 'Lista de análisis',
      analysisCreate: 'Crear análisis/clase',
      analysisEdit: 'Editar análisis',
      customersEdit: 'Editar empresa',
      customersIndex: 'Empresas',
      editOffer: 'Editar oferta',
      editOrder: 'Editar pedido',
      editOrderSample: 'Editar Muestra',
      newOffer: 'Crear oferta',
      offersIndex: 'Lista de ofertas',
      ordersIndex: 'Lista de pedidos',
      showOffer: 'Oferta',
      usersIndex: 'Lista de usuarios',
    },
    welcome: 'Bienvenido',
  },
  loginView: {
    accessButton: 'Iniciar Sesión',
    passwordForgotLabel: '¿Has olvidado tu contraseña?',
    passwordInput: 'Contraseña',
    title: 'Tu',
    titleBlue: 'Laboratorio',
    usernameInput: 'Nombre de usuario',
  },
  logout: {
    label: 'Salir',
    successMessage: 'Se cerró la sesión correctamente',
  },
  menuItems: {
    analysesIndex: 'Análisis',
    customersIndex: 'Empresas',
    invoicesIndex: 'Facturas',
    newsAdminIndex: 'Noticias',
    newsUserIndex: 'Noticias',
    offersIndex: 'Ofertas',
    ordersIndex: 'Pedidos',
    requestOffer: 'Solicitar oferta',
    samplesIndex: 'Estado de las muestras',
    usersIndex: 'Usuarios',
  },
  news: {
    create: {
      abstract: 'Texto abstract',
      attachments: 'Cargar archivos adjuntos',
      createNews: 'Crear noticias',
      draftSubmitButton: 'Guardar como borrador',
      formHeader: 'Crear noticias',
      image: 'Subir imagen destacada',
      imagePlaceholder: 'Sube una imagen de 1200 x 400',
      isPublished: 'Publicada',
      publishedAt: 'Fecha de publicación',
      submitButton: 'Guardar y publicar noticias',
      success: 'La noticia se creó correctamente',
      text: 'Texto de la noticia',
      title: 'Título de la noticia',
    },
    delete: {
      cancelled: '¡Eliminación cancelada!',
      confirm: '¿Estás seguro de que quieres borrar las noticias?',
      success: '¡Noticias eliminadas!',
    },
    detail: {
      attachments: 'Documentos adjuntos',
      title: 'Detalle de noticias',
    },
    index: {
      noRecords: 'No hay noticias',
    },
    update: {
      bulkSuccess: 'Noticias modificadas correctamente',
      draftSubmitButton: 'Poner en borrador',
      formHeader: 'Editar noticias',
      submitButton: 'Editar noticias',
      success: 'La noticia se modificó correctamente',
    },
  },
  offer: {
    comment: 'Comentar',
    create: {
      formHeader: 'Crear oferta',
      request: '¿Quieres solicitar una oferta?',
      requestSubtitle: 'Rellene los campos de abajo',
    },
    createError: '¡Se ha producido un error al crear la oferta!',
    customer: 'Empresa',
    docNumber: 'Número de documento',
    downloadPdf: 'Descargar PDF',
    editSuccess: '¡La oferta se modificó correctamente!',
    expirationDate: 'Fecha final de validez',
    help: {
      field: {
        admin: {
          docNumber: 'Información sobre el número de documento del formulario',
        },
      },
      filter: {
        admin: {
          docNumber: 'Información sobre el número de documento',
        },
      },
    },
    index: {
      createOffer: 'Crear oferta',
    },
    issuedAt: 'Fecha del documento',
    ondaOfferId: 'ID Documento Onda',
    ourReference: 'Nuestra referencia',
    pdfFileId: 'Cargar PDF',
    request: {
      marketingConsentText1: 'Usted nos autoriza a ponernos en contacto con usted y enviarle por correo electrónico información sobre nuestros servicios/productos/eventos y promociones que puedan interesarle.',
      marketingConsentText2: 'No es necesaria la autorización para procesar los datos con este fin, pero corre el riesgo de perderse algo que podría interesarle.',
      message: 'mensaje',
      offer: 'Número de oferta anterior',
      previousOfferNotFind: 'No se encontró la oferta anterior',
      privacyConsent: ' ',
      privacyConsentLabel: 'Tratamiento de datos personales',
      privacyConsentText: 'He leído y acepto la información relativa al procesamiento de datos personales de conformidad con el Reglamento de la UE 2016/679 arts. 13 y 14',
      success: '¡La solicitud se ingresó correctamente!',
    },
    revisionDate: 'Fecha de revisión',
    revisionNumber: 'Número de revisión de la oferta',
    status: 'Estado',
    update: {
      formHeader: 'Editar oferta',
      success: 'La oferta se modificó correctamente',
    },
    uploadCsvFile: 'Cargar archivo excel',
    yourReference: 'Vuestra referencia',
  },
  orders: {
    backWithoutSaving: 'Volver a los pedidos',
    create: {
      abortCreate: 'Anular',
      cancelled: 'Operación anulada',
      confirmCancel: 'Estás a punto de salir del cambio de pedido. ¿Está seguro de que desea continuar sin guardar los cambios actuales?',
      confirmSend: 'Estás a punto de salir del cambio de pedido. ¿Estás seguro de que quieres continuar y guardar el pedido actual?',
      createOrder: 'Crear pedido',
      customer: 'Razón Social',
      customerId: 'Razón Social',
      customerOrderNumber: 'Número de pedido',
      saved: 'Pedido guardado correctamente',
      sent: 'Pedido con código {orderId} enviado correctamente',
      separateInvoice: '¿Quieres que este pedido se facture por separado?',
      separateInvoiceNo: 'No',
      separateInvoiceYes: 'Sí',
      success: 'Pedido creado correctamente',
      title: 'Nombre del pedido',
    },
    delete: {
      cancelled: 'Eliminación anulada',
      confirm: '¿Quieres eliminar el pedido?',
      success: 'Pedido eliminado',
    },
    goToSummary: 'Ir al resumen',
    orderSamples: 'Muestras',
    print: 'Imprimir',
    samples: {
      abortUpload: 'Anular',
      confirmUpload: '¿Estás seguro de que quieres continuar? Los datos cargados anteriormente se anularán.',
      importError: 'Error de importación, comprueba que el archivo está en el formato correcto',
      importSuccess: 'Importado correctamente',
      uploadReport: 'Cargar Informe',
      uploadReports: 'Enviar',
    },
    saveForLater: 'Guardar para más tarde',
    send: 'Enviar',
    update: {
      success: 'El pedido se actualizó correctamente',
      updateOrder: 'Actualizar',
    },
    warning: {
      confirm: 'Sí',
      expired: 'Oferta caducada',
      message: 'La oferta ha caducado, ¿quieres solicitar una nueva?',
      reject: 'No',
      success: 'Ok',
    },
  },
  paneContent: {
    testLabel: 'prueba',
  },
  passwordRecoverView: {
    emailInput: 'Correo electrónico',
    passwordRecoveredMessage: 'Se han enviado instrucciones adicionales a su dirección de correo electrónico',
    submitButton: 'Enviar',
    title: 'Recupera tu contraseña',
  },
  passwordResetView: {
    confirmPasswordInput: 'Confirmar Contraseña',
    passwordChangedMessage: 'La contraseña se cambió correctamente',
    passwordInput: 'Contraseña',
    submitButton: 'Enviar',
    title: 'Introduce tu nueva contraseña',
  },
  permissions: {
    admin: 'Súper Administrador',
    billing: 'Facturación',
    billingManage: 'Gestión',
    billingView: 'Visualización',
    customers: 'Empresas',
    customersManage: 'Gestión',
    customersView: 'Visualización',
    news: 'Noticias',
    newsManage: 'Gestión',
    newsView: 'Visualización',
    offers: 'Ofertas',
    offersManage: 'Gestión',
    offersView: 'Visualización',
    orders: 'Pedidos',
    ordersManage: 'Gestión',
    ordersView: 'Visualización',
    samplesStatus: 'Estado de las muestras',
    samplesStatusManage: 'Gestión',
    samplesStatusView: 'Visualización',
    title: 'Permisos',
    users: 'Usuarios',
    usersManage: 'Gestión',
    usersView: 'Visualización',
  },
  samples: {
    clone: {
      confirm: '¿Está seguro de que desea duplicar la muestra?',
      success: 'Muestra duplicada',
    },
    create: {
      abortCreate: 'Anular',
      actions: 'acciones',
      address: {
        addNew: 'Añadir nuevo',
        address: 'Dirección',
        city: 'Ciudad',
        name: 'Razón Social',
        phoneNumber: 'Número de Teléfono',
        province: 'provincia',
        zip: 'Código postal ',
      },
      comments: 'Comentarios',
      costCenter: 'Centro de costes del cliente',
      createSample: 'Ingresar muestra',
      customerAddress: 'Dirección del cliente',
      customerHeaderData: '¿Desea insertar los datos del cliente final en el encabezado del informe de prueba?',
      customerHeaderDataNo: 'No',
      customerHeaderDataYes: 'Sí',
      customerProductName: 'Código de muestra para clientes',
      date: 'Fecha de lote',
      downloadExample: 'Descargar XLSX',
      fileSize: 'Archivos PDF de menos de 10 MB',
      finalCustomer: 'Cliente final',
      finalCustomerRef: 'Referencia del cliente final',
      import: 'Carga masiva de XLSX',
      labSampleCode: 'Código producto',
      lot: 'Lote',
      notes: 'Notas para clientes',
      packagesNumber: 'Número de muestras',
      productName: 'Nombre del producto',
      sampleDescription: 'Descripción de la muestra',
      sector: 'Sector',
      success: 'Muestra creada correctamente',
    },
    delete: {
      cancelled: '¡Cancelación anulada!',
      confirm: '¿Está seguro de que desea eliminar la muestra?',
      success: '¡muestra cancelada!',
    },
    sampleDescriptions: {
      anhydrousFluid: 'Fluido anhidro',
      anhydrousPaste: 'Pasta anhidra',
      biphasic: 'Bifásico',
      capsule: 'Cápsula',
      emulsion: 'Emulsión',
      liquid: 'Líquido',
      liquidGel: 'Gel líquido',
      loosePowder: 'Polvo libre',
      oil: 'Aceite',
      packaging: 'Packaging',
      patch: 'Patch',
      pressedPowder: 'Polvo compactado/',
      sanitaryTowelsDiapers: 'Absorbentes - pañales',
      tablet: 'Comprimida',
      validation: 'Validaciones',
      wipes: 'Toallitas',
    },
    sectors: {
      biocide: 'Presidio MC',
      chemical: 'Químico',
      cosmetic: 'Cosmética',
      detergents: 'Detergencia',
      foodSupplement: 'Alimentario',
      medicalDevice: 'Dispositivo médico',
      other: 'Otro',
      packaging: 'Material de embalaje',
      pharmaceutical: 'Farmacéutico',
    },
    showReportsList: 'Mostrar todos los informes',
    update: {
      confirm: '¿Está seguro de que desea modificar la muestra?',
      success: 'La muestra se actualizó correctamente',
      updateSample: 'Actualizar muestra',
      waitApproval: 'Para modificar esta muestra debe esperar la aprobación del administrador.',
    },
  },
  table: {
    massiveActions: {
      submit: 'Aplicar',
      title: 'Aplicar estado a todos ',
    },
  },
  tableButtons: {
    addAnalyses: 'Añadir análisis',
    addAnalysisToClass: 'Agregar a una clase',
    change: 'Editar',
    changePdf: 'Sustituir pdf',
    cloneSample: 'Duplica muestra ',
    completeOrder: 'Finalizar pedido',
    deleteNews: 'Eliminar noticias',
    deleteOrder: 'Eliminar pedido',
    deleteSample: 'Eliminar muestra',
    disable: 'Deshabilitar ',
    disableAnalysis: 'Deshabilitar ',
    disableCustomer: 'Deshabilitar ',
    disableRawData: 'Deshabilitar datos brutos',
    disableSelected: 'Deshabilitar lo seleccionado',
    disableUser: 'Deshabilitar ',
    downloadExcel: 'Descargar Excel',
    downloadOffer: 'Descargar oferta',
    downloadSampleReport: 'Descargar el informe',
    editArticle: 'Editar artículo',
    editCustomer: 'Editar',
    editNews: 'Editar noticias',
    editOrder: 'Editar pedido',
    editSample: 'Editar muestra',
    editUser: 'Editar',
    enable: 'Habilitar',
    enableAnalysis: 'Habilitar',
    enableCustomer: 'Habilitar',
    enableRawData: 'Habilitar datos brutos',
    enableUser: 'Habilitar',
    exportExcel: 'Descargar Excel',
    exportRawData: 'Descargar datos brutos',
    reloadExcel: 'Recargar Excel',
    renameSample: 'Cambiar nombre',
    seeDetail: 'Ver',
    selectOnlyThisSampleOff: 'Ver todos los análisis',
    selectOnlyThisSampleOn: 'Mostrar solo los análisis asociados',
    sendVerificationEmail: 'Enviar correo electrónico de verificación',
    showAnalyses: 'Mostrar análisis',
    showAttachment: 'Abrir archivo adjunto',
    showInvoice: 'Abrir factura',
    showOrderSamples: 'Mostrar muestras',
    showSamples: 'Ver muestras',
    showUsers: 'Ver todos los usuarios',
    uploadPdf: 'Cargar PDF',
    uploadSampleReport: 'Cargar PDF',
    userOrders: 'Pedidos',
  },
  tooltips: {
    analysesIndex: 'En esta pantalla puede monitorear el estado de su análisis de muestra.',
    customerOrderNumber: 'Introduzca su número de orden de compra para que aparezca correctamente en la factura',
    invoicesIndex: 'Facturas asociadas a mi empresa',
    massiveXlsOrder: 'Compila el archivo de Excel siguiendo las instrucciones que encontrarás en su interior y úsalo para cargar varias muestras al mismo tiempo',
    newSample: {
      comments: 'Notas adicionales (no incluidas en el informe de prueba)',
      costCenter: 'centro de costes del cliente',
      customerSampleCode: 'Código de muestra para clientes',
      description: 'Descripción de la muestra (seleccionar de la lista)',
      finalCustomer: 'Cliente final que encargó la muestra',
      finalCustomerRef: 'Referencia del cliente final que solicitó la muestra',
      labSampleCode: 'Código de artículo de muestra',
      lot: 'Lote de muestra',
      lotDate: 'Fecha de producción del lote',
      lotNumber: 'Número de muestras (obligatorio)',
      note: 'Notas del cliente (presentes en el informe de prueba)',
      pdfUpload: 'Cargue los archivos que contienen todos los documentos que desea comunicar a complife para la correcta ejecución de la prueba (por ejemplo, especificaciones, ficha técnica), o que son necesarios para la ejecución de la prueba (por ejemplo, INCI)',
      productName: 'Nombre de la muestra (obligatorio)',
      sector: 'Sector de producto de muestra (obligatorio: seleccione de la lista)',
    },
    offersIndex: 'Lista de ofertas actualmente válidas',
    orderName: 'Asigne un nombre a su pedido',
    ordersIndex: 'En esta pantalla, puede realizar y realizar un seguimiento de sus pedidos',
    remainingAnalyses: 'Número de pruebas restantes. Válido sólo para abonos y tramos',
    requestOffer: 'En esta pantalla podrá solicitar la emisión de una oferta caducada o para un nuevo tipo de prueba',
    samplesIndex: 'En esta pantalla, puede realizar un seguimiento de sus muestras y descargar los resultados',
  },
  users: {
    create: {
      abortCreate: 'Anular',
      createUser: 'Crear usuario',
      customer: 'Razón Social',
      email: 'Correo electrónico',
      emailConfirmation: 'Confirmar correo electrónico',
      familyName: 'Apellidos',
      function: 'Función',
      functions: {
        administration: 'Administración',
        logistics: 'Logística',
        production: 'Producción',
        purOff: 'Departamento de compras',
        qa: 'Garantía de calidad',
        qc: 'Control de calidad',
        rd: 'Investigación y desarrollo',
      },
      givenName: 'Nombre',
      password: 'Contraseña',
      passwordConfirmation: 'Confirmar Contraseña',
      phone: 'Teléfono',
      role: 'Rol',
      success: 'El usuario se creó correctamente',
    },
    update: {
      invitationSent: 'Correo electrónico de verificación enviado',
      success: 'El usuario se actualizó correctamente',
      updateUser: 'Editar usuario',
    },
  },
};
