export default {
  accreditedTestModal: {
    abort: 'Annulla',
    confirm: 'Termina modifica e aggiungi analisi',
    description: 'Per aggiungere questa analisi devi prima compilare il campo "Lotto" per ',
    lot: 'Lotto per campione "{0}":',
    next: 'Modifica e passa al campione successivo',
    samples: 'nessun campione | un altro {n} campione | altri {n} campioni',
    title: 'Attenzione!',
    warning: 'Attenzione, analisi accreditata',
  },
  analyses: {
    create: {
      abortCreate: 'Annulla',
      accreditedTest: 'Test accreditato',
      analysisTypeFilter: 'Filtra per tipologia',
      brand: 'Marca',
      brandCode: 'Codice marca',
      class: 'Classe',
      classType: 'Tipo classe',
      code: 'Codice articolo',
      commodityCategory: 'Descrizione codice merceologico',
      commodityCode: 'Categoria merceologica',
      department: 'Dipartimento',
      departmentCode: 'Codice dipartimento',
      description: 'Descrizione articolo',
      dynamicClass: 'Classe dinamica',
      expirationDate: 'Fine validità articolo',
      extraDescription: 'Descrizione aggiuntiva articolo',
      family: 'Famiglia',
      familyCode: 'Codice famiglia',
      fixedClass: 'Classe fissa',
      formHeader: 'Crea Analisi/Classe',
      maxAnalysesNumber: 'Numero massimo analisi',
      measure: 'Descrizione misura',
      measureCode: 'Codice misura',
      measureUnitCode: 'Unità di misura',
      method: 'Descrizione metodo',
      methodCode: 'Codice metodo',
      nature: 'Descrizione codice natura',
      natureCode: 'Codice natura',
      removeAnalysis: 'Elimina',
      searchAnalyses: 'Cerca analisi',
      singleAnalysis: 'Analisi',
      statisticCategory: 'Descrizione categoria statistica',
      statisticCode: 'Codice categoria statistica',
      submitButton: 'Invia',
      success: 'Analisi creata con successo',
    },
    index: {
      createAnalysis: 'Crea Analisi/Classe',
    },
    update: {
      addToClass1: 'Aggiungi ad una classe',
      addToClass2: 'Aggiungi alla classe',
      formHeader: 'Modifica Analisi',
      success: 'Analisi aggiornata con successo',
    },
  },
  collapse: {
    CHIM_approved: 'RdP Chimico',
    CHIM_draft: 'Anteprima RdP Chim.',
    MICR_approved: 'RdP Microbiologico',
    MICR_draft: 'Anteprima RdP Micr.',
    Preview_draft: 'Anteprima excel',
  },
  common: {
    noPermissonRoute: 'Non hai i privilegi per poter accedere a questa pagina!',
  },
  customers: {
    create: {
      abortCreate: 'Annulla',
      address: 'Indirizzo',
      agent: 'Agente',
      canViewRawData: 'Visualizza dati grezzi',
      certifiedEmail: 'Email',
      city: 'Città',
      createCustomer: 'Crea Azienda',
      district: 'Provincia',
      fiscalCode: 'Codice Fiscale',
      internalReference: 'Onda Id',
      isEnabled: 'Attivato',
      mobile: 'Cellulare',
      name: 'Nome',
      phoneNumber: 'Telefono',
      sdi: 'SDI',
      success: 'Azienda creata con successo',
      vat: 'P.Iva',
      zip: 'CAP',
    },
    update: {
      success: 'Azienda aggiornata con successo',
      updateCustomer: 'Modifica azienda',
    },
  },
  dynamicAnalysesModal: {
    abort: 'Annulla',
    confirm: 'Termina modifica e aggiungi analisi',
    wrongNumber: 'Selezionare {n} analisi per procedere!',
  },
  enums: {
    accreditedTest: {
      no: 'No',
      partially: 'Contiene test accreditati',
      yes: 'Si',
    },
    analysisStatuses: {
      accepted: 'Accettato',
      completed: 'Completato',
      deleted: 'Cancellato',
      inprogress: 'In lavorazione',
      none: 'Creato',
      pending: 'In attesa',
      rejected: 'Rifiutato',
      suspended: 'Sospeso',
    },
    commodityCategory: {
      chemical: 'Chimico',
      microbiological: 'Microbiologico',
      vitro: 'Vitro',
    },
    commons: {
      no: 'No',
      yes: 'Si',
    },
    complifeLaboratory: {
      CHIM: 'Chimico',
      MICR: 'Microbiologico',
    },
    enabledStatus: {
      disabled: 'Disabilitato',
      enabled: 'Abilitato',
    },
    functions: {
      administration: 'Amministrazione',
      logistics: 'Logistica',
      production: 'Produzione',
      purOff: 'Ufficio Acquisti',
      qa: 'Assicurazione Qualità',
      qc: 'Controllo Qualità',
      rd: 'Ricerca e Sviluppo',
      undefined: '-',
    },
    newsStatuses: {
      published: 'Pubblicato',
      unpublished: 'Bozza',
    },
    offerType: {
      brackets: 'Scaglioni',
      list: 'Listino',
      null: 'Nessuno',
      quantity: 'Abbonamento',
    },
    orderLocked: {
      acceptance: 'Lavorazione bloccata',
      none: 'Nessun blocco',
      report: 'Rapporto di prova bloccato',
    },
    orderStatuses: {
      accepted: 'Accettato',
      canceled: 'Cancellato',
      completed: 'Completato',
      draft: 'Bozza',
      draftCompleted: 'Bozza completa',
      inProgress: 'In lavorazione',
      rejected: 'Rifiutato',
      sent: 'Inviato',
    },
    sampleStatuses: {
      accepted: 'Accettato',
      canceled: 'Cancellato',
      concluded: 'Concluso',
      created: 'Creato',
      draft: 'Draft',
      inprogress: 'In lavorazione',
      pending: 'In attesa',
      rejected: 'Rifiutato',
      suspended: 'Sospeso',
    },
  },
  filters: {
    accreditedTest: 'Test accreditato',
    actions: 'Azioni',
    active: 'Abilitato',
    activeInactive: 'Abilitato / Disabilitato',
    additionalDescription: 'Descrizione aggiuntiva',
    address: 'Indirizzo',
    analysisCode: 'Codice Analisi',
    analysisCount: 'Totale Analisi',
    applyFilter: 'Applica Filtri',
    canViewRawData: 'Dati grezzi',
    childrenCount: 'Numero Analisi',
    city: 'Città',
    class: 'Classe',
    clearFilter: 'Svuota',
    code: 'Codice Articolo',
    comment: 'Commento',
    commodityCategory: 'Categoria merceologica',
    companyName: 'Nome azienda',
    complifeLaboratory: 'Laboratorio',
    countryCode: 'Codice lingua',
    createdAt: 'Data',
    customDescription: 'Descrizione',
    customer: 'Azienda',
    customerCode: 'Codice cliente',
    customerName: 'Ragione sociale',
    customerOrderNumber: 'Numero ordine cliente',
    customerProductName: 'Codice campione cliente',
    date: 'Data',
    description: 'Descrizione',
    disabled: 'Disabilitato',
    docNumber: 'Numero documento',
    draft: 'Bozza',
    email: 'Email',
    emailAddress: 'Indirizzo email',
    enabled: 'Abilitato',
    endDate: 'a',
    expirationDate: 'Data fine validità',
    familyName: 'Cognome',
    from: 'Da',
    fromDate: 'Da',
    function: 'Funzione',
    givenName: 'Nome',
    inactive: 'Disabilitato',
    invoiceNumber: 'Numero fattura',
    isEnabled: 'Attivato',
    isVerified: 'Verificato',
    issuedAt: 'Data documento',
    labSampleCode: 'Codice campione',
    lot: 'Lotto',
    method: 'Metodo',
    methodCode: 'Codice metodo',
    name: 'Nome',
    newsTitle: 'Titolo news',
    offer: 'Offerta',
    offerEnabled: 'Attiva',
    offerNumber: 'Numero offerta',
    ondaOrderId: 'Id ONDA',
    order: 'Ordine',
    orderConfirmId: 'Numero conferma d\'ordine',
    orderId: 'ID Ordine',
    orderLocked: 'Ordine bloccato',
    orderName: 'Nome ordine',
    orderNumber: 'Numero Ordine',
    ordersSent: 'Inviato',
    phone: 'Telefono',
    price: 'Importo',
    productName: 'Nome prodotto',
    publishedAt: 'Data pubblicazione',
    quantity: 'Quantità',
    rejectReason: 'Motivo rifiuto',
    remaining: 'Rimanenti',
    reportsList: 'Elenco rapporti',
    revisionDate: 'Data revisione',
    revisionNumber: 'Numero revisione',
    role: 'Ruolo',
    sampleCount: 'Numero Campioni',
    sampleId: 'ID Campione',
    sampleReportCodes: 'Codici rapporto di prova',
    sector: 'Settore',
    sent: 'Inviata',
    sentAt: 'Data invio ordine',
    startDate: 'Da',
    status: 'Stato',
    testType: 'Tipologia test',
    title: 'Nome Ordine',
    toDate: 'A',
    type: 'Tipologia',
    user: 'Utente',
    vat: 'P.Iva',
    zipCode: 'CAP',
  },
  global: {
    add: 'Aggiungi',
    apply: 'Applica',
    attachment: 'Allegato',
    back: 'Indietro',
    backToList: 'Torna alla lista',
    browse: 'Sfoglia',
    cancel: 'Annulla',
    confirmUploadCancel: 'Sei sicuro/a di voler rimuovere il file?',
    connectionError: 'Errore di comunicazione con il server!',
    continue: 'Continua',
    downloadPdf: 'Download PDF',
    edit: 'Modifica',
    english: 'Inglese',
    fileExceeded: 'Caricare un file di dimensioni < {0}Mb',
    help: 'Aiuto',
    italian: 'Italiano',
    lang: 'Lingua',
    noMultipleFilesUpload: 'è necessario rimuovere il file precendente prima di poter caricarne uno nuovo!',
    notCsvFile: 'Caricare un file csv valido!',
    notPdfFile: 'Caricare un file pdf valido!',
    removeSwitchPdf: 'Sostituisci/Rimuovi PDF',
    requiredFields: 'I campi contrassegnati con * sono obbligatori',
    select: 'Seleziona',
    spanish: 'Spagnolo',
    uploadFilesLimitExceeded: 'E\' stato raggiunto il numero massimo di file caricabili',
    uploadPdf: 'Upload PDF',
    warning: 'Attenzione!',
  },
  header: {
    titles: {
      analysesIndex: 'Elenco analisi',
      analysisCreate: 'Crea analisi/classe',
      analysisEdit: 'Modifica Analisi',
      customersEdit: 'Modifica Azienda',
      customersIndex: 'Aziende',
      editOffer: 'Modifica offerta',
      editOrder: 'Modifica Ordine',
      editOrderSample: 'Modifica Campione',
      newOffer: 'Crea offerta',
      offersIndex: 'Elenco offerte',
      ordersIndex: 'Elenco Ordini',
      showOffer: 'Offerta',
      usersIndex: 'Elenco utenti',
    },
    welcome: 'Benvenuto',
  },
  loginView: {
    accessButton: 'Accedi',
    passwordForgotLabel: 'Password dimenticata?',
    passwordInput: 'Password',
    title: 'Il tuo',
    titleBlue: 'laboratorio',
    usernameInput: 'Nome utente',
  },
  logout: {
    label: 'Esci',
    successMessage: 'Logout effettuato con successo',
  },
  menuItems: {
    analysesIndex: 'Analisi',
    customersIndex: 'Aziende',
    invoicesIndex: 'Fatture',
    newsAdminIndex: 'News',
    newsUserIndex: 'News',
    offersIndex: 'Offerte',
    ordersIndex: 'Ordini',
    requestOffer: 'Richiedi offerta',
    samplesIndex: 'Stato Campioni',
    usersIndex: 'Utenti',
  },
  news: {
    create: {
      abstract: 'Testo abstract',
      attachments: 'Carica allegati',
      createNews: 'Crea news',
      draftSubmitButton: 'Salva in bozza',
      formHeader: 'Crea news',
      image: 'Carica immagine in primo piano',
      imagePlaceholder: 'Caricare un\'immagine 1200 x 400',
      isPublished: 'Pubblicata',
      publishedAt: 'Data di pubblicazione',
      submitButton: 'Salva e pubblica news',
      success: 'News creata con successo',
      text: 'Testo news',
      title: 'Titolo news',
    },
    delete: {
      cancelled: 'Eliminazione annullata!',
      confirm: 'Sei sicuro di voler eliminare la news?',
      success: 'News eliminata!',
    },
    detail: {
      attachments: 'Documenti in allegato',
      title: 'Dettaglio news',
    },
    index: {
      noRecords: 'Non sono presenti news',
    },
    update: {
      bulkSuccess: 'News modificate con successo',
      draftSubmitButton: 'Metti in bozza',
      formHeader: 'Modifica news',
      submitButton: 'Modifica news',
      success: 'News modificata con successo',
    },
  },
  offer: {
    comment: 'Commento',
    create: {
      formHeader: 'Crea Offerta',
      request: 'Vuoi richiedere un\'offerta?',
      requestSubtitle: 'Compila i campi sottostanti',
    },
    createError: 'Errore durante la creazione dell\'offerta!',
    customer: 'Azienda',
    docNumber: 'Numero documento',
    downloadPdf: 'Scarica PDF',
    editSuccess: 'Offerta modificata correttamente!',
    expirationDate: 'Data fine validità',
    help: {
      field: {
        admin: {
          docNumber: 'Info numero documento form',
        },
      },
      filter: {
        admin: {
          docNumber: 'Info numero documento',
        },
      },
    },
    index: {
      createOffer: 'Crea Offerta',
    },
    issuedAt: 'Data documento',
    ondaOfferId: 'ID Documento Onda',
    ourReference: 'Nostro riferimento',
    pdfFileId: 'Carica PDF',
    request: {
      marketingConsentText1: 'Ci autorizzi a contattarti e inviarti per email informazioni sui nostri servizi/prodotti/eventi e promozioni che potrebbero interessarti.',
      marketingConsentText2: 'L’autorizzazione al trattamento dei dati per questa finalità non è necessaria, ma rischieresti di perderti qualcosa che potrebbe interessarti.',
      message: 'Messaggio',
      offer: 'Numero offerta precedente',
      previousOfferNotFind: 'Offerta precedente non trovata',
      privacyConsent: ' ',
      privacyConsentLabel: 'Trattamento dei Dati Personali',
      privacyConsentText: 'Ho letto e accetto l’informativa relativa al Trattamento dei Dati Personali ai sensi del Regolamento UE 2016/679 artt. 13 e 14',
      success: 'Richiesta inserita con successo!',
    },
    revisionDate: 'Data revisione',
    revisionNumber: 'Numero revisione offerta',
    status: 'Stato',
    update: {
      formHeader: 'Modifica Offerta',
      success: 'Offerta modificata con successo',
    },
    uploadCsvFile: 'Caricare file excel',
    yourReference: 'Vostro riferimento',
  },
  orders: {
    backWithoutSaving: 'Torna agli ordini',
    create: {
      abortCreate: 'Annulla',
      cancelled: 'Operazione annullata',
      confirmCancel: 'Stai per uscire dalla modifica ordine. Sei sicuro di voler proseguire senza salvare le modifiche correnti?',
      confirmSend: 'Stai per uscire dalla modifica ordine. Sei sicuro di voler proseguire e salvare l\'ordine corrente?',
      createOrder: 'Crea Ordine',
      customer: 'Ragione Sociale',
      customerId: 'Ragione Sociale',
      customerOrderNumber: 'Numero ordine',
      saved: 'Ordine salvato con successo',
      sent: 'Ordine con codice {orderId} inviato con successo',
      separateInvoice: 'Vuoi che questo ordine sia fatturato separatamente?',
      separateInvoiceNo: 'No',
      separateInvoiceYes: 'Sì',
      success: 'Ordine creato con successo',
      title: 'Nome Ordine',
    },
    delete: {
      cancelled: 'Eliminazione annullata',
      confirm: 'Vuoi eliminare l\'ordine?',
      success: 'Ordine eliminato',
    },
    goToSummary: 'Vai al riepilogo',
    orderSamples: 'Campioni',
    print: 'Stampa',
    samples: {
      abortUpload: 'Annulla',
      confirmUpload: 'Sei sicuro di voler continuare? I dati precedentemente caricati verranno sovrascritti.',
      importError: 'Errore di import, controlla che il file sia nel formato corretto',
      importSuccess: 'Import avvenuto con successo',
      uploadReport: 'Carica Rapporto',
      uploadReports: 'Invia',
    },
    saveForLater: 'Salva per dopo',
    send: 'Invia',
    update: {
      success: 'Ordine aggiornato con successo',
      updateOrder: 'Aggiorna',
    },
    warning: {
      confirm: 'Sì',
      expired: 'Offerta scaduta',
      message: 'L\'offerta è scaduta, vuoi richiederne una nuova?',
      reject: 'No',
      success: 'Ok',
    },
  },
  paneContent: {
    testLabel: 'prova',
  },
  passwordRecoverView: {
    emailInput: 'Email',
    passwordRecoveredMessage: 'Ulteriori istruzioni sono state inviate al tuo indirizzo e-mail',
    submitButton: 'Invia',
    title: 'Recupera la tua password',
  },
  passwordResetView: {
    confirmPasswordInput: 'Conferma password',
    passwordChangedMessage: 'Password modificata correttamente',
    passwordInput: 'Password',
    submitButton: 'Invia',
    title: 'Inserisci la tua nuova password',
  },
  permissions: {
    admin: 'Super Admin',
    billing: 'Fatturazione',
    billingManage: 'Gestione',
    billingView: 'Visualizzazione',
    customers: 'Aziende',
    customersManage: 'Gestione',
    customersView: 'Visualizzazione',
    news: 'News',
    newsManage: 'Gestione',
    newsView: 'Visualizzazione',
    offers: 'Offerte',
    offersManage: 'Gestione',
    offersView: 'Visualizzazione',
    orders: 'Ordini',
    ordersManage: 'Gestione',
    ordersView: 'Visualizzazione',
    samplesStatus: 'Stato Campioni',
    samplesStatusManage: 'Gestione',
    samplesStatusView: 'Visualizzazione',
    title: 'Permessi',
    users: 'Utenti',
    usersManage: 'Gestione',
    usersView: 'Visualizzazione',
  },
  samples: {
    clone: {
      confirm: 'Sei sicuro di voler duplicare il campione?',
      success: 'Campione duplicato',
    },
    create: {
      abortCreate: 'Annulla',
      actions: 'Azioni',
      address: {
        addNew: 'Aggiungi nuovo',
        address: 'Indirizzo',
        city: 'Città',
        name: 'Ragione sociale',
        phoneNumber: 'Numero di telefono',
        province: 'Provincia',
        zip: 'CAP',
      },
      comments: 'Commenti',
      costCenter: 'Centro di costo cliente',
      createSample: 'Inserisci Campione',
      customerAddress: 'Indirizzo cliente',
      customerHeaderData: 'Vuoi inserire nell\'intestazione del rapporto di prova i dati del cliente finale?',
      customerHeaderDataNo: 'No',
      customerHeaderDataYes: 'Sì',
      customerProductName: 'Codice Campione cliente',
      date: 'Data lotto',
      downloadExample: 'Download XLSX',
      fileSize: 'File PDF di dimensioni inferiori a 10 MB',
      finalCustomer: 'Cliente Finale',
      finalCustomerRef: 'Riferimento Cliente Finale',
      import: 'Caricamento massivo XLSX',
      labSampleCode: 'Codice prodotto',
      lot: 'Lotto',
      notes: 'Note del cliente',
      packagesNumber: 'N° Campioni',
      productName: 'Nome prodotto',
      sampleDescription: 'Descrizione Campione',
      sector: 'Settore',
      success: 'Campione creato con successo',
    },
    delete: {
      cancelled: 'Cancellazione annullata!',
      confirm: 'Sei sicuro di voler cancellare il campione?',
      success: 'Campione cancellato!',
    },
    sampleDescriptions: {
      anhydrousFluid: 'Anidro fluido',
      anhydrousPaste: 'Anidro pasta',
      biphasic: 'Bifasico',
      capsule: 'Capsula',
      emulsion: 'Emulsione',
      liquid: 'Liquido',
      liquidGel: 'Liquido Gel',
      loosePowder: 'Polvere libera',
      oil: 'Olio',
      packaging: 'Packaging',
      patch: 'Patch',
      pressedPowder: 'Polvere compattata/',
      sanitaryTowelsDiapers: 'Assorbenti-Pannolini',
      tablet: 'Compressa',
      validation: 'Validazioni',
      wipes: 'Salviettine',
    },
    sectors: {
      biocide: 'Presidio M.C.',
      chemical: 'Chimico',
      cosmetic: 'Cosmetico',
      detergents: 'Detergenza',
      foodSupplement: 'Alimentare',
      medicalDevice: 'Dispositivo medico',
      other: 'Altro',
      packaging: 'Materiale di confezionamento',
      pharmaceutical: 'Farmaceutico',
    },
    showReportsList: 'Mostra tutti i rapporti',
    update: {
      confirm: 'Sei sicuro di voler modificare il campione?',
      success: 'Campione aggiornato con successo',
      updateSample: 'Aggiorna Campione',
      waitApproval: 'Per modificare questo campione occorre aspettare l\'approvazione dell\'amministratore.',
    },
  },
  table: {
    massiveActions: {
      submit: 'Applica',
      title: 'Applica stato a tutti',
    },
  },
  tableButtons: {
    addAnalyses: 'Aggiungi analisi',
    addAnalysisToClass: 'Aggiungi ad una classe',
    change: 'Modifica',
    changePdf: 'Sostituisci pdf',
    cloneSample: 'Duplica Campione',
    completeOrder: 'Finalizza Ordine',
    deleteNews: 'Elimina news',
    deleteOrder: 'Elimina ordine',
    deleteSample: 'Cancella Campione',
    disable: 'Disabilita',
    disableAnalysis: 'Disabilita',
    disableCustomer: 'Disabilita',
    disableRawData: 'Disabilita dati grezzi',
    disableSelected: 'Disabilita selezionati',
    disableUser: 'Disabilita',
    downloadExcel: 'Scarica Excel',
    downloadOffer: 'Scarica offerta',
    downloadSampleReport: 'Scarica rapporto',
    editArticle: 'Modifica articolo',
    editCustomer: 'Modifica',
    editNews: 'Modifica news',
    editOrder: 'Modifica Ordine',
    editSample: 'Modifica Campione',
    editUser: 'Modifica',
    enable: 'Abilita',
    enableAnalysis: 'Abilita',
    enableCustomer: 'Abilita',
    enableRawData: 'Abilita dati grezzi',
    enableUser: 'Abilita',
    exportExcel: 'Scarica Excel',
    exportRawData: 'Scarica dati grezzi',
    reloadExcel: 'Ricarica excel',
    renameSample: 'Rinomina',
    seeDetail: 'Vedi',
    selectOnlyThisSampleOff: 'Visualizza tutte le analisi',
    selectOnlyThisSampleOn: 'Visualizza solo analisi associate',
    sendVerificationEmail: 'Invia mail di verifica',
    showAnalyses: 'Mostra analisi',
    showAttachment: 'Apri allegato',
    showInvoice: 'Apri fattura',
    showOrderSamples: 'Mostra campioni',
    showSamples: 'Vedi campioni',
    showUsers: 'Visualizza tutti gli utenti',
    uploadPdf: 'Carica pdf',
    uploadSampleReport: 'Carica PDF',
    userOrders: 'Ordini',
  },
  tooltips: {
    analysesIndex: 'In questa schermata puoi monitorare lo stato delle analisi del tuo campione',
    customerOrderNumber: 'Inserisci il tuo numero di ordine d’acquisto, affinchè compaia correttamente in fattura',
    invoicesIndex: 'Fatture associate alla mia azienza',
    massiveXlsOrder: 'Compila il file Excel seguendo le istruzioni che trovi al suo interno e utilizzalo per caricare più campioni contemporaneamente',
    newSample: {
      comments: 'Note aggiuntive (non presenti nel Rapporto di Prova)',
      costCenter: 'Centro di costo del cliente',
      customerSampleCode: 'Codice campione del cliente',
      description: 'Descrizione del campione (selezionare da elenco)',
      finalCustomer: 'Cliente finale che ha commisionato il campione',
      finalCustomerRef: 'Rif. del Cliente finale che ha commissionato il campione',
      labSampleCode: 'Codice articolo del campione',
      lot: 'Lotto del campione',
      lotDate: 'Data di produzione del lotto',
      lotNumber: 'Numero di campioni (obbligatorio)',
      note: 'Note del cliente (presenti nel Rapporto di Prova)',
      pdfUpload: 'Carica i file contenenti tutti i documenti che vuoi comunicare a complife per una corretta esecuzione del test (es. specifiche, scheda tecnica), o che sono richiesti per l’esecuzione del test (es. INCI)',
      productName: 'Nome del campione (obbligatorio)',
      sector: 'Settore merceologico del campione (obbligatorio - selezionare da elenco)',
    },
    offersIndex: 'Elenco offerte in corso di validità',
    orderName: 'Assegna un nome al tuo ordine',
    ordersIndex: 'In questa schermata puoi effettuare e monitorare i tuoi ordini',
    remainingAnalyses: 'Numero test rimanenti. Valido solo per Abbonamenti e Scaglioni',
    requestOffer: 'In questa schermata puoi richiedere l’emissione di una offerta scaduta o per una nuova tipologia di test',
    samplesIndex: 'In questa schermata puoi monitorare i tuoi campioni e scaricare i risultati',
  },
  users: {
    create: {
      abortCreate: 'Annulla',
      createUser: 'Crea Utente',
      customer: 'Ragione sociale',
      email: 'Email',
      emailConfirmation: 'Conferma Email',
      familyName: 'Cognome',
      function: 'Funzione',
      functions: {
        administration: 'Amministrazione',
        logistics: 'Logistica',
        production: 'Produzione',
        purOff: 'Ufficio Acquisti',
        qa: 'Assicurazione Qualità',
        qc: 'Controllo Qualità',
        rd: 'Ricerca e Sviluppo',
      },
      givenName: 'Nome',
      password: 'Password',
      passwordConfirmation: 'Conferma Password',
      phone: 'Telefono',
      role: 'Ruolo',
      success: 'Utente creato con successo',
    },
    update: {
      invitationSent: 'Mail di verifica inviata',
      success: 'Utente aggiornato con successo',
      updateUser: 'Modifica utente',
    },
  },
};
