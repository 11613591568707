import Vue from 'vue';
import VueRouter from 'vue-router';
import { Message } from 'element-ui';
import storage from 'store';
import store from '../store';
import PostLogin from '../views/PostLogin.vue';
import PreLogin from '../views/PreLogin.vue';
import usersApi from '../api/users';
import auth from '../services/auth';
import acl from '../services/acl';
import i18n from '../i18n';

Vue.use(VueRouter);

const RouterViewComponentFactory = componentName => Vue.component(componentName, {
  render(createElement) {
    return createElement('router-view');
  },
});


const routes = [
  {
    path: '/admin',
    name: 'postLogin',
    component: PostLogin,
    // redirect: '/admin/offers',
    children: [
      {
        path: 'users',
        name: 'users',
        redirect: 'users/index',
        meta: {
          routePermissions: ['usersView'],
        },
        component: () => import(/* webpackChunkName: "users" */ '../views/users/Users.vue'),
        children: [
          {
            path: 'index',
            name: 'usersIndex',
            meta: {
              sectionTitle: 'menuItems.usersIndex',
              routePermissions: ['usersView'],
              hasFloatingActionButton: true,
            },
            component: () => import(/* webpackChunkName: "usersIndex" */ '../views/users/UsersIndex.vue'),
          },
          {
            path: ':userId/orders',
            name: 'userOrdersIndex',
            meta: {
              menuActiveRouteName: 'ordersIndex',
              routePermissions: ['usersView'],
            },
            component: () => import(/* webpackChunkName: "ordersIndex" */ '../views/orders/OrdersIndex.vue'),
          },
          {
            path: ':userId',
            name: 'usersEdit',
            meta: {
              menuActiveRouteName: 'usersIndex',
              routePermissions: ['usersManage'],
            },
            component: () => import(/* webpackChunkName: "usersIndex" */ '../views/users/UsersIndex.vue'),
          },
        ],
      },
      {
        path: 'news-admin',
        name: 'newsAdmin',
        redirect: 'news-admin/index',
        component: RouterViewComponentFactory('NewsAdmin'),
        children: [
          {
            path: 'index',
            name: 'newsAdminIndex',
            meta: {
              routePermissions: ['admin', 'newsView'],
              sectionTitle: 'menuItems.newsAdminIndex',
              hasFloatingActionButton: true,
            },
            component: () => import(/* webpackChunkName: "newsIndexAdmin" */ '../views/news/admin/NewsIndexAdmin.vue'),
          },
          {
            path: 'new',
            name: 'newsAdminCreate',
            meta: {
              routePermissions: ['admin', 'newsManage'],
              menuActiveRouteName: 'newsAdminIndex',
            },
            component: () => import(/* webpackChunkName: "newsAdminCreate" */ '../views/news/admin/NewsCreateAdmin.vue'),
          },
          {
            path: ':newsId',
            name: 'newsAdminEdit',
            meta: {
              routePermissions: ['admin', 'newsManage'],
              menuActiveRouteName: 'newsAdminIndex',
            },
            component: () => import(/* webpackChunkName: "newsAdminCreate" */ '../views/news/admin/NewsCreateAdmin.vue'),
          },
        ],
      },
      {
        path: 'news-user',
        name: 'newsUser',
        redirect: 'news-user/index',
        component: RouterViewComponentFactory('NewsUser'),
        children: [
          {
            path: 'index',
            name: 'newsUserIndex',
            meta: {
              routePermissions: ['user', 'newsView'],
              sectionTitle: 'menuItems.newsUserIndex',
              hasFloatingActionButton: false,
            },
            component: () => import(/* webpackChunkName: "newsIndexUser" */ '../views/news/user/NewsIndexUser.vue'),
          },
          {
            path: ':newsId',
            name: 'newsUserDetail',
            meta: {
              routePermissions: ['user', 'newsView'],
              menuActiveRouteName: 'newsUserIndex',
            },
            component: () => import(/* webpackChunkName: "newsUserDetail" */ '../views/news/user/NewsDetailUser.vue'),
          },
        ],
      },
      {
        path: 'samples',
        name: 'samples',
        redirect: 'samples/index',
        component: () => import(/* webpackChunkName: "samples" */ '../views/samples/Samples.vue'),
        children: [
          {
            path: 'index',
            name: 'samplesIndex',
            meta: {
              sectionTitle: 'menuItems.samplesIndex',
              hasFloatingActionButton: false,
              sectionInfoLabel: 'tooltips.samplesIndex',
              routePermissions: ['samplesStatusView'],
            },
            component: () => import(/* webpackChunkName: "samplesIndex" */ '../views/samples/SamplesIndex.vue'),
          },
          {
            path: ':sampleId/edit/:orderId',
            name: 'editSample',
            meta: {
              menuActiveRouteName: 'ordersIndex',
              routePermissions: ['samplesStatusManage'],
            },
            component: () => import(/* webpackChunkName: "samplesIndex" */ '../views/samples/SamplesIndex.vue'),
          },
          {
            path: ':sampleId/edit/:orderId/uploadSampleReport',
            name: 'uploadSampleReport',
            meta: {
              menuActiveRouteName: 'ordersIndex',
              routePermissions: ['samplesStatusManage'],
            },
            component: () => import(/* webpackChunkName: "samplesIndex" */ '../views/samples/SamplesIndex.vue'),
          },
        ],
      },
      {
        path: 'offers',
        name: 'offers',
        redirect: 'offers/index',
        meta: {
          routePermissions: ['offersView'],
        },
        component: () => import(/* webpackChunkName: "offers" */ '../views/offers/Offers.vue'),
        children: [
          {
            path: 'index',
            name: 'offersIndex',
            meta: {
              sectionTitle: 'menuItems.offersIndex',
              sectionInfoLabel: 'tooltips.offersIndex',
              hasFloatingActionButton: true,
              routePermissions: ['offersView'],
            },
            component: () => import(/* webpackChunkName: "offersIndex" */ '../views/offers/OffersIndex.vue'),
          },
          {
            path: 'request',
            name: 'requestOffer',
            meta: {
              sectionTitle: 'menuItems.requestOffer',
              sectionInfoLabel: 'tooltips.requestOffer',
              routePermissions: ['user', 'offersView'],
              menuActiveRouteName: 'requestOffer',
            },
            component: () => import(/* webpackChunkName: "requestOffer" */ '../views/offers/RequestOffer.vue'),
          },
          {
            path: 'show/:id',
            name: 'showOffer',
            meta: {
              routePermissions: ['offersView'],
              menuActiveRouteName: 'offersIndex',
            },
            component: () => import(/* webpackChunkName: "showOffer" */ '../views/offers/ShowOffer.vue'),
          },
        ],
      },
      {
        path: 'orders',
        name: 'orders',
        redirect: 'orders/index',
        meta: {
          routePermissions: ['ordersView'],
        },
        component: () => import(/* webpackChunkName: "ordersView" */ '../views/orders/Orders.vue'),
        children: [
          {
            path: 'index',
            name: 'ordersIndex',
            meta: {
              sectionTitle: 'menuItems.ordersIndex',
              sectionInfoLabel: 'tooltips.ordersIndex',
              hasFloatingActionButton: true,
              routePermissions: ['ordersView'],
            },
            component: () => import(/* webpackChunkName: "ordersIndex" */ '../views/orders/OrdersIndex.vue'),
          },
          {
            path: ':orderId/edit',
            name: 'editOrder',
            meta: {
              menuActiveRouteName: 'ordersIndex',
              routePermissions: ['ordersManage'],
            },
            component: () => import(/* webpackChunkName: "editOrder" */ '../views/orders/EditOrder.vue'),
          },
          {
            path: ':orderId/edit/samples/:sampleId/edit',
            name: 'editOrderSample',
            meta: {
              menuActiveRouteName: 'ordersIndex',
              routePermissions: ['ordersManage'],
            },
            component: () => import(/* webpackChunkName: "editOrder" */ '../views/orders/EditOrder.vue'),
          },
          {
            path: ':orderId/samples',
            name: 'orderSamples',
            meta: {
              menuActiveRouteName: 'ordersIndex',
              routePermissions: ['ordersView'],
            },
            component: RouterViewComponentFactory('OrderSamples'),
            children: [
              {
                path: 'index',
                name: 'orderSamplesIndex',
                meta: {
                  menuActiveRouteName: 'ordersIndex',
                  sectionTitle: 'menuItems.ordersIndex',
                  routePermissions: ['ordersView'],
                },
                component: () => import(/* webpackChunkName: "ordersIndex" */ '../views/orders/samples/OrderSamplesIndex.vue'),
              },
              {
                path: ':sampleId/analyses',
                name: 'orderSampleAnalyses',
                meta: {
                  menuActiveRouteName: 'ordersIndex',
                  routePermissions: ['ordersView'],
                },
                component: RouterViewComponentFactory('OrderSampleAnalyses'),
                children: [
                  {
                    path: 'index',
                    name: 'orderSampleAnalysesIndex',
                    meta: {
                      menuActiveRouteName: 'ordersIndex',
                      sectionTitle: 'menuItems.analysesIndex',
                      routePermissions: ['ordersView'],
                    },
                    component: () => import(/* webpackChunkName: "orderSampleAnalysesIndex" */ '../views/orders/samples/analyses/OrderSampleAnalysesIndex.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: ':orderId/summary',
            name: 'orderSummary',
            meta: {
              menuActiveRouteName: 'ordersIndex',
              routePermissions: ['ordersView'],
            },
            component: () => import(/* webpackChunkName: "orderSummary" */ '../views/orders/OrderSummary.vue'),
          },
        ],
      },
      {
        path: 'customers',
        name: 'customers',
        redirect: 'customers/index',
        meta: {
          routePermissions: ['admin', 'customersView'],
        },
        component: () => import(/* webpackChunkName: "customers" */ '../views/customers/Customers.vue'),
        children: [
          {
            path: 'index',
            name: 'customersIndex',
            meta: {
              sectionTitle: 'menuItems.customersIndex',
              routePermissions: ['admin', 'customersView'],
              hasFloatingActionButton: true,
            },
            component: () => import(/* webpackChunkName: "customersIndex" */ '../views/customers/CustomersIndex.vue'),
          },
          {
            path: ':customerId',
            name: 'customersEdit',
            meta: {
              menuActiveRouteName: 'customersIndex',
              routePermissions: ['admin', 'customersManage'],
            },
            component: () => import(/* webpackChunkName: "customersIndex" */ '../views/customers/CustomersIndex.vue'),
          },
        ],
      },
      {
        path: 'invoices',
        name: 'invoices',
        redirect: 'invoices/index',
        meta: {
          routePermissions: ['user', 'billingView'],
        },
        component: () => import(/* webpackChunkName: "invoices" */ '../views/invoices/Invoices.vue'),
        children: [
          {
            path: 'index',
            name: 'invoicesIndex',
            meta: {
              sectionTitle: 'menuItems.invoicesIndex',
              sectionInfoLabel: 'tooltips.invoicesIndex',
              hasFloatingActionButton: true,
              routePermissions: ['user', 'billingView'],
            },
            component: () => import(/* webpackChunkName: "invoicesIndex" */ '../views/invoices/InvoicesIndex.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    name: 'preLogin',
    component: PreLogin,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        beforeEnter: (to, from, next) => {
          const authToken = storage.get('authToken');
          if (store.getters.loggedUser && authToken) {
            next({ name: 'postLogin' });
          } else {
            next();
          }
        },
      },
      {
        path: '/activate-user',
        name: 'activateUser',
        component: () => import(/* webpackChunkName: "activateUser" */ '../views/PasswordReset.vue'),
      },
      {
        path: '/password-reset',
        name: 'passwordReset',
        component: () => import(/* webpackChunkName: "passwordReset" */ '../views/PasswordReset.vue'),
      },
      {
        path: '/password-recover',
        name: 'passwordRecover',
        component: () => import(/* webpackChunkName: "passwordRecover" */ '../views/PasswordRecover.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!!to.matched.find(route => route.name === 'postLogin') && !store.getters.loggedUser) {
    try {
      const response = await usersApi.getInfo();
      store.dispatch('setLoggedUser', response.data);
      if (!store.getters.loggedUser) {
        next({ name: 'Login' });
        return;
      }
    } catch (err) {
      if (err.response.status === 401 || !auth.authTokenExists()) {
        auth.logout();
        store.dispatch('setLoggedUser', null);
        next({ name: 'Login' });
        return;
      }
    }
  }

  const canNavigate = acl.userCanUse((to.meta || {}).routePermissions, true);
  if (canNavigate) next();
  else {
    next({ name: 'Login' });
    Message({ showClose: true, type: 'error', message: i18n.t('common.noPermissonRoute') });
  }
});

export default router;
