import { USER_FUNCTIONS_DESC } from '@/config/constants';

const usersColumns = {
  givenName: {
    key: 'givenName',
    label: 'givenName',
    type: 'text',
    sortable: 'custom',
  },
  familyName: {
    key: 'familyName',
    label: 'familyName',
    type: 'text',
    sortable: true,
  },
  companyName: {
    key: 'customerName',
    label: 'customerName',
    type: 'text',
    sortable: false,
  },
  function: {
    key: 'function',
    label: 'function',
    type: 'encoded',
    enum: 'functions',
    decode: value => USER_FUNCTIONS_DESC[value],
    sortable: false,
  },
  // role: {
  //   key: 'permissions',
  //   label: 'role',
  //   type: 'function',
  //   sortable: false,
  //   get: value => (value === 0 ? 'Administrator' : 'User'),
  // },
  email: {
    key: 'email',
    label: 'emailAddress',
    type: 'text',
    sortable: true,
  },
  isEnabled: {
    label: 'isEnabled',
    type: 'boolean',
    minWidth: 40,
    sortable: false,
    align: 'center',
  },
  isVerified: {
    label: 'isVerified',
    type: 'boolean',
    minWidth: 40,
    sortable: false,
    align: 'center',
  },
  actions: {
    key: 'actions',
    label: 'actions',
    type: 'actions',
    sortable: false,
    minWidth: 150,
    buttons: [
      {
        name: 'editUser',
        icon: 'fas fa-pencil', // FontAwesome icon class
        color: 'blue-light', // color defined in common-variables.scss
        permissions: ['usersManage'],
      },
      {
        name: 'disableUser',
        icon: 'fas fa-user-alt-slash',
        color: 'red-light',
        permissions: ['admin', 'usersManage'],
        showCondition: row => !!row.isEnabled,
      },
      {
        name: 'enableUser',
        icon: 'fas fa-user',
        color: 'blue',
        permissions: ['admin', 'usersManage'],
        showCondition: row => !row.isEnabled,
      },
      {
        name: 'userOrders',
        icon: 'fas fa-truck',
        color: 'orange',
        permissions: ['admin', 'ordersView'],
      },
      {
        name: 'sendVerificationEmail',
        icon: 'fas fa-envelope',
        color: 'blue',
        permissions: ['admin', 'usersManage'],
        showCondition: row => !row.isVerified,
      },
    ],
  },
};

export default usersColumns;
