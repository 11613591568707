const sampleAnalysesColumns = {
  commodityCategory: {
    key: 'commodityCategory',
    label: 'testType',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  docNumber: {
    key: 'docNumber',
    label: 'offerNumber',
    type: 'text',
    sortable: 'custom',
    minWidth: 170,
  },
  code: {
    key: 'code',
    label: 'code',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  description: {
    key: 'description',
    label: 'description',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  extraDescription: {
    key: 'extraDescription',
    label: 'additionalDescription',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  method: {
    key: 'method',
    label: 'method',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  accreditedTest: {
    key: 'accreditedTest',
    label: 'accreditedTest',
    type: 'function',
    sortable: 'custom',
    minWidth: 200,
    enum: 'accreditedTest',
    get: (value, row) => {
      let rowChildrenAccredited = [];
      let {
        children,
      } = row;
      if (!children) {
        children = [];
      }
      rowChildrenAccredited = children
        .filter(child => child.analysis.accreditedTest) ?? [];
      if (row.accreditedTest
        || (children.length > 0 && rowChildrenAccredited.length === children.length)
      ) {
        return 'yes';
      }
      if (rowChildrenAccredited.length === 0) {
        return 'no';
      }
      if (rowChildrenAccredited.length > 0 && rowChildrenAccredited.length < children.length) {
        return 'partially';
      }
      return row.accreditedTest ? 'yes' : 'no';
    },
  },
  remaining: {
    key: 'remaining',
    label: 'remaining',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
    help: 'remainingAnalyses',
  },
};

export default sampleAnalysesColumns;
